










































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import InvoiceForm from '@/components/InvoiceForm.vue'
import { Invoice } from '@/interfaces'

export default Vue.extend({
  components: { Nav, InvoiceForm },
  props: {
    invoice: {
      type: Object as () => Invoice,
      required: false,
      default: () => {
        return {
          id: null,
        }
      },
    },
  },
  data() {
    return {
      displayAlert: false,
      invoiceId: '',
      invoiceData: {
        parentInvoiceId: null as number | null,
        parentInvoiceType: null as string | null,
        parentInvoiceNumber: null as string | null,
      } as Invoice,
      triggerSave: false,
    }
  },
  mounted() {
    this.invoiceId = this.$route.params.id
    this.displayAlert = false
  },
  methods: {
    goBack() {
      this.triggerSave = !this.triggerSave
      this.$router.back()
    },
    updateInvoiceData(invoice: Invoice) {
      this.invoiceData = invoice
    },
    getParentInvoiceInfo() {
      let type = ''
      if (this.invoiceData?.parentInvoiceType === 'bordereau') {
        type += 'Bordereau'
      } else if (this.invoiceData.parentInvoiceType === 'decompte') {
        type += 'Décompte'
      } else {
        type += 'Facture'
      }

      return type + ' ' + (this.invoiceData.parentInvoiceNumber ?? '')
    },
  },
})
